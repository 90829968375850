<template>
  <main class="journey">
    <!--
    //# Section Journey -->
    <section class="l-title l-journey-content" data-scroll-section>
      <h1 class="title title-general" data-scroll="fade-up">The Journey</h1>
      <div class="items-list">
        <article class="item-c">
          <div class="item item-01 item-right">
            <div data-scroll="time-line">
              <div class="line" data-scroll="time-line" data-scroll-delay="2"></div>
            </div>
            <div data-scroll="fade-left-journey-1">
              <div class="sprite-c sprite-c-01" data-scroll>
                <figure class="sprite"></figure>
              </div>
            </div>
          </div>
          <div class="text-c item-left" data-scroll="fade-right-journey-1">
            <p class="text"><b>A Focus on the Work</b></p>
            <p class="text">With decades of direct experience serving companies and leading businesses in more than 35 countries from bases in London, New York and Singapore, in 2008 Chris van Someren left the treacle of corporate life in order to focus more single-mindedly on the work itself. Against the backdrop of the global financial crisis, Chris nonetheless convened a team of friends and rivals that became Ascentador.</p>
          </div>
        </article>

        <article class="item-c">
          <div class="item item-02 item-left">
            <div data-scroll="time-line">
              <span class="line" data-scroll="time-line" data-scroll-delay="2"></span>
            </div>
            <div data-scroll="fade-right-journey-2">
              <div class="sprite-c sprite-c-01" data-scroll>
                <figure class="sprite sprite-02"></figure>
              </div>
            </div>
          </div>
          <div class="text-c item-right" data-scroll="fade-left-journey-2">
            <p class="text"><b>An Amalgam of Diverse Backgrounds</b></p>
            <p class="text">Ascentador is diverse, and draws on experience in large corporations, management consultancy, executive recruitment, and talent management. Despite the pressure of those early days, the team remained true to their ideals as internationalists, passionate innovators of the consulting process and insightful solutions providers to clients on a global stage. In times of crisis and in times in plenty, Ascentador remains devoted to pragmatism, candor and a focus on long-term relationships.</p>
          </div>
        </article>

        <article class="item-c">
          <div class="item item-03 item-right">
            <div data-scroll="time-line">
              <span class="line" data-scroll="time-line" data-scroll-delay="2"></span>
            </div>
            <div data-scroll="fade-left-journey-1">
              <div class="sprite-c sprite-c-01" data-scroll>
                <figure class="sprite sprite-03"></figure>
              </div>
            </div>
          </div>
          <div class="text-c item-left" data-scroll="fade-right-journey-1">
            <p class="text"><b>A Decade of Growth & Acceleration</b></p>
            <p class="text">Moving well beyond a set of static, standardized offerings, Ascentador evolved over time to provide support and expertise on a range of talent issues, including organizational design, global talent migration, diversity & inclusion, succession planning, professional development, and board alignment strategies. In everything it does, Ascentador combines vast business leadership experience with remarkable problem-solving capabilities.</p>
          </div>
        </article>

        <article class="item-c">
          <div class="item item-04 item-left">
            <div data-scroll="time-line">
              <span class="line" data-scroll="time-line" data-scroll-delay="2"></span>
            </div>
            <div data-scroll="fade-right-journey-2">
              <div class="sprite-c sprite-c-01" data-scroll>
                <figure class="sprite sprite-04"></figure>
              </div>
            </div>  
          </div>
          <div class="text-c item-right" data-scroll="fade-left-journey-2">
            <p class="text"><b>A Bright, Collaborative Future</b></p>
            <p class="text">Now comprised of a small band of multi-faceted professionals who bring their capabilities to bear in whatever combinations and locations best serve the client, Ascentador operates on an “open architecture” partnership basis. With no associates or other support staff, Ascentador partners instead operate in a completely hands-on manner, personally working to develop and implement solutions for clients as part of the larger team. Chris van Someren and his collaborators at Ascentador are acknowledged experts in change management, executive recruitment, and organizational development. Their shared passion for the work, and for working together, is evident.</p>
          </div>
        </article>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'Journey',
  metaInfo() {
      return { 
          title: "Ascentador | The Journey",
          meta: [
              { name: 'description', content:  'Ascentador was founded in 2008 by a group of like-minded partners determined to reinvent our marketplace and has since evolved into a “open architecture” platform of independent experts who bring their capabilities to bear in whatever combinations and locations best serve our clients.'},
          ]
      }
  },
  data() {
    return {};
  },
  mounted() {
    this.$emit('view-mounted');
  },
};
</script>
